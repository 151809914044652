import React from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/01-VIVA_EOY24-Ventana.json';
const EndScreen: React.FC = () => {
  const animationOne = {
    loop: false,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <section className="main-view end--view">
      <div className="container-fluid">
        <div className="row">
          <aside className="main-view__aside aside--color2 col-lg-5 col-12">
            <div className="lottie-player">
              <Lottie options={animationOne} />
            </div>
          </aside>
          <section className="main-view__right col-lg-7 col-12">
            <div className="main-article-intro">
              <figure className="base-logo">
                <img src="../../../assets/images/logo/viva-white.svg" alt="Viva"/>
              </figure>
              <article className='end-article'>
                <h1 className="text-white text-64">Hemos terminado de <strong className="text-green300">destapar tu 2024</strong>.</h1>
                <p className="text-white text-20">Si quieres conocer tus destinos favoritos del 2025, tus preferencias de vuelo y tu estilo al viajar, sigue volando con Viva ¡y podrás explorar todo eso y mucho más!</p>
              </article>
             
            </div>
            <figure className="intro-plain">
              <img src="../../../assets/images/elements/plane-tail.png" alt="Plain"/>
            </figure>
          </section>
        </div>
      </div>
    </section>
  );
};

export default EndScreen;