import { UserInformation } from '../types/vivaaerobus';

export enum CustomerProfile {
  TopTraveler = 'TopTraveler',
  VIP = 'VIP',
  OnTheGo = 'OnTheGo',
  Doters = 'Doters',
  Aficionado = 'Aficionado',
}

const getCustomerProfile = (
  customer: Partial<UserInformation>
): CustomerProfile => {
  const { backupUser } = customer;

  const isTopTraveler =
    backupUser?.seats?.brand !== 'VIP' &&
    parseInt(backupUser?.kilometers_flown || '0') >= 30000;
  const isVIP = backupUser?.seats?.brand === 'VIP';
  const isOnTheGo = backupUser?.channel === 'App';
  const isDoters =
    backupUser?.membership_doters_class !== '' &&
    backupUser?.benefits_used_doters_points_acumulation !== '0';

  if (isTopTraveler) {
    return CustomerProfile.TopTraveler;
  }
  if (isVIP) {
    return CustomerProfile.VIP;
  }
  if (isOnTheGo) {
    return CustomerProfile.OnTheGo;
  }
  if (isDoters) {
    return CustomerProfile.Doters;
  }

  return CustomerProfile.Aficionado;
};

export default { getCustomerProfile };
