import { IBackupUser, UserInformationResponse } from '../types/vivaaerobus';

export const parseUserResponse = (
  userInfo: UserInformationResponse,
  backupUser: Partial<IBackupUser>
) => {
  try {
    if (!userInfo || !userInfo.data) {
      return backupUser;
    }
    const {
      segmentsFlown,
      kilometersFlown,
      timeFlown,
      carbonFootprints,
      smileAndFlyScans,
      preferences,
      membership,
      benefitsUsed,
    } = userInfo.data;
    const aircrafts = preferences.length > 0 ? preferences[0].aircraft : [];
    const aircraft = aircrafts.length > 0 ? aircrafts[0] : {};
    const promotionalBookings =
      preferences.length > 0 ? preferences[0].promotionalBookings : 0;
    const destinations =
      preferences.length > 0 ? preferences[0].destinations : [];
    const origins = preferences.length > 0 ? preferences[0].origin : [];
    const origin = origins.length > 0 ? origins[0] : {};
    const bundles = preferences.length > 0 ? preferences[0].bundle : [];
    const bundle = bundles.length > 0 ? bundles[0] : {};
    const seats = preferences.length > 0 ? preferences[0].seats : {};
    const cobrandCardType = membership.length > 1 ? membership[1].type : '';
    const benefitsUsedDoters =
      benefitsUsed.length > 0
        ? benefitsUsed.filter((b) => Object.keys(b).includes('doters'))
        : [];
    console.log(benefitsUsedDoters);
    const doters =
      benefitsUsedDoters.length > 0 ? benefitsUsedDoters[0].doters || [] : [];
    const doter = doters.length > 0 ? doters[0] : {};
    console.log(doter);
    const vivaFans =
      benefitsUsed.length > 0 ? benefitsUsed[0].vivaFan || [] : [];
    const vivaFan = vivaFans.length > 0 ? vivaFans[0] : {};
    const bookings = bundle.flights || 0;
    const channel = preferences.length > 0 ? preferences[0].channel : '';

    const cloneBackupUser = { ...backupUser };

    cloneBackupUser.bookings = `${bookings}`;
    cloneBackupUser.segments_flown = `${segmentsFlown}`;
    cloneBackupUser.kilometers_flown = `${kilometersFlown}`;
    cloneBackupUser.time_flown = `${timeFlown}`;
    cloneBackupUser.carbon_footprints = `${carbonFootprints}`;
    cloneBackupUser.smile_and_flight_count = `${smileAndFlyScans}`;
    cloneBackupUser.aircraft_type = aircraft.type || '';
    cloneBackupUser.aircraft_flights_num = `${aircraft.flights || 0}`;
    cloneBackupUser.promotional_bookings = `${promotionalBookings}`;
    cloneBackupUser.checked_baggages_count =
      backupUser.checked_baggages_count || '0';
    cloneBackupUser.destinations = destinations.map((d) => ({
      code: `${d.code || ''}`,
      type: `${d.type || ''}`,
      name: `${d.name || ''}`,
      num: `${d.flights || 0}`,
    }));
    cloneBackupUser.origin = {
      code: `${origin.code || ''}`,
      type: `${origin.type || ''}`,
      num: `${origin.flights || 0}`,
      name: `${origin.type || ''}`,
    };
    cloneBackupUser.bundle = {
      type: `${bundle.type || ''}`,
      name: `${bundle.name || ''}`,
      num: `${bundle.flights || 0}`,
    };
    cloneBackupUser.seats = {
      type: `${seats.location || ''}`,
      type_num: `${backupUser?.seats?.type_num || seats.flights || 0}`,
      brand: `${seats.brand || ''}`,
      brand_num: `${backupUser?.seats?.brand_num || seats.flights || 0}`,
    };
    cloneBackupUser.channel = channel;
    cloneBackupUser.channel_num = backupUser.channel_num || '0';
    cloneBackupUser.membership_doters_class =
      membership.length > 0 ? membership[0].level || '' : '';
    cloneBackupUser.doters_date_of_joining =
      membership.length > 0 ? membership[0].start || '' : '';
    cloneBackupUser.cobrand_card_type = cobrandCardType;
    cloneBackupUser.doters_tickets = `${doter.rewardTickets || 0}`;
    cloneBackupUser.benefits_used_doters_points_acumulation = `${
      doter.pointsAcumulation || 0
    }`;
    cloneBackupUser.benefits_used_doters_points_used = `${
      doter.pointsUsed || 0
    }`;
    cloneBackupUser.membership_vivafan_num = `${vivaFan.bookings || 0}`;

    return cloneBackupUser;
  } catch (error) {
    console.error(error);
  }
  return backupUser;
};
