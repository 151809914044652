import React from 'react';

type Props = {
  number?: number;
  variant?: string;
};


const BoxPromoComponent: React.FC<Props> = ({ number }: Props) => {
  return (
    <div className="item-box item-box--generic">
      <div className="item-box__number center" style={{ zIndex: 2, background: 'initial', color: 'white' }}>{number}</div>
      <figure className='item-image'>
        <img src="/assets/images/grid/promo.svg" alt="Pomoción" />
      </figure>
    </div>
  );
};

export default BoxPromoComponent;