import React from 'react';

type Props = {
  number?: number;
  variant?: string;
};


const BoxKilometersFlowComponent: React.FC<Props> = ({ number }: Props) => {
  return (
    <div className="item-box item-box--6">
      <div className="item-box__number_variant">{number}</div>
      <figure>
        <img src="/assets/images/grid/routes.svg" alt="Rutas" />
      </figure>
    </div>
  );
};

export default BoxKilometersFlowComponent;