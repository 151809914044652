import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationA from '../../lotties/Backup05_A.json';
import animationB from '../../lotties/Backup05_B.json';
import animationC from '../../lotties/Backup05_C.json';
import animationD from '../../lotties/Backup05_D.json';
import animationE from '../../lotties/Backup05_E.json';
import animationAMobile from '../../lotties/Backup05_A_Mobile.json';
import animationBMobile from '../../lotties/Backup05_B_Mobile.json';
import animationCMobile from '../../lotties/Backup05_C_Mobile.json';
import animationDMobile from '../../lotties/Backup05_D_Mobile.json';
import animationEMobile from '../../lotties/Backup05_E_Mobile.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: number;
  customer: Partial<UserInformation>;
}

const ModalBNComponent: React.FC<Props> = ({ customer }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [bundleNumber, setBundleNumber] = useState<number>(5); // Default to 5

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth < 768);
      }
    };

    handleResize(); // Set initial value

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const bundleName = customer.backupUser?.bundle?.name || '';
    switch (bundleName.toLowerCase()) {
      case 'smart':
        setBundleNumber(1);
        break;
      case 'extra':
        setBundleNumber(2);
        break;
      case 'light':
        setBundleNumber(3);
        break;
      case 'zero':
        setBundleNumber(4);
        break;
      default:
        setBundleNumber(5); // Default to 5 if empty or tied
        break;
    }
  }, [customer]);

  const animationMap: { [key: number]: { desktop: any; mobile: any } } = {
    1: {
      desktop: animationA,
      mobile: animationAMobile,
    },
    2: {
      desktop: animationB,
      mobile: animationBMobile,
    },
    3: {
      desktop: animationC,
      mobile: animationCMobile,
    },
    4: {
      desktop: animationD,
      mobile: animationDMobile,
    },
    5: {
      desktop: animationE,
      mobile: animationEMobile,
    },
  };

  const animationData = animationMap[bundleNumber]
    ? isMobile
      ? animationMap[bundleNumber].mobile
      : animationMap[bundleNumber].desktop
    : isMobile
    ? animationAMobile
    : animationA; // Default

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      className="box-swal box--modalBN"
      data-extra={'true'}
      data-info={bundleNumber}
    >
      <div className="row">
        <div className="box-modal-cols col-12">
          <section className="box-green">
            <article>
              {bundleNumber >= 1 && bundleNumber <= 3 && (
                <p className="text-white h3">
                  Volaste {`${customer.backupUser?.bundle?.num || 0}`} veces en:
                </p>
              )}
              {bundleNumber === 4 && (
                <p className="text-white h3">
                  Hiciste la mayoría de tus viajes en
                </p>
              )}
              {bundleNumber === 1 && (
                <>
                  <h2 className="text-green300 bundle-title">
                    Modalidad Smart.
                  </h2>
                  <p className="text-white h3 font-300 bundle-subtitle">
                    ¡Volando con todo equipado!
                  </p>
                </>
              )}
              {bundleNumber === 2 && (
                <>
                  <h2 className="text-green300 bundle-title">
                    Modalidad Extra.
                  </h2>
                  <p className="text-white h3 font-300 bundle-subtitle">
                    Un extra siempre cae bien.
                  </p>
                </>
              )}
              {bundleNumber === 3 && (
                <>
                  <h2 className=" text-green300 bundle-title">
                    Modalidad Light.
                  </h2>
                  <p className="text-white h3 font-300 bundle-subtitle">
                    Viajando con lo necesario.
                  </p>
                </>
              )}
              {bundleNumber === 4 && (
                <>
                  <h2 className="h3 text-green300 bundle-title">
                    Modalidad Zero.
                  </h2>
                  <p className="text-white h3 font-300 bundle-subtitle">
                    ¡Viajar ligero es viajar mejor!
                  </p>
                </>
              )}
              {bundleNumber === 5 && (
                <>
                  <h2 className="h3 text-green300 ">
                    A veces más ligero, a veces más equipado.
                  </h2>
                  <p className="text-white h3 font-300">
                    Modalidad Smart, Extra, Light o Zero… ¡Este año, elegiste un
                    poquito de todo!
                  </p>
                </>
              )}
            </article>
            <div className="lottie-player">
              <Lottie options={animationOptions} />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ModalBNComponent;
