import React from 'react';

type Props = {
  number?: number;
  variant?: string;
};


const BoxAircraftComponent: React.FC<Props> = ({ number }: Props) => {
  return (
    <div className="item-box item-box--generic">
      <div className="item-box__number right">{number}</div>

      <figure className="item-image">
        <img src="/assets/images/grid/aircraft.jpg" alt="Rutas" />
      </figure>
    </div>
  );
};

export default BoxAircraftComponent;