/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import Box1Component from '../Boxes/box1.component';
import Box2Component from '../Boxes/box2.component';
import Box3Component from '../Boxes/box3.component';
import Box4Component from '../Boxes/box4.component';
import Box5Component from '../Boxes/box5.component';
import Box6Component from '../Boxes/box6.component';
import Box7Component from '../Boxes/box7.component';
import Box8Component from '../Boxes/box8.component';
import Box9Component from '../Boxes/box9.component';
import Box10Component from '../Boxes/box10.component';
import Box11Component from '../Boxes/box11.component';
import Box12Component from '../Boxes/box12.component';
import { CustomerProfileState, userFlags, UserInformation } from '../../types/vivaaerobus';
import helper from '../../helpers/helper';
import helperProfile from '../../helpers/helperProfile';
import { Masonry } from 'react-masonry';
import BoxKilometersFlowComponent from '../Boxes/box_kilometers_flow.component';
import BoxAircraftComponent from '../Boxes/box_aircraft.component';
import BoxPromoComponent from '../Boxes/box_promo.component';
import BoxTimeFLowComponent from '../Boxes/box_time_flow.component';
import BoxCarbonFootprintsComponent from '../Boxes/box_carbon_footprints.component';
import BoxBundleComponent from '../Boxes/box_bundle.component';

interface BoxSwalProps {
  customer: Partial<UserInformation>;
  selectedBox: number | null;
  setSelectedBox: (index: number | null) => void;
}

const backupModals: ((customer: Partial<UserInformation>, number: number) => JSX.Element)[] = [
  (customer: Partial<UserInformation>, number: number) => <BoxKilometersFlowComponent number={number} />, // kilometers_flow
  (customer: Partial<UserInformation>, number: number) => <BoxAircraftComponent number={number} />, // aircraft_type
  (customer: Partial<UserInformation>, number: number) => <BoxTimeFLowComponent number={number} />, // Time flow
  (customer: Partial<UserInformation>, number: number) => <BoxBundleComponent number={number} />, // Bundle name
  (customer: Partial<UserInformation>, number: number) => <BoxPromoComponent number={number} />, // Promotional booking
  (customer: Partial<UserInformation>, number: number) => <BoxCarbonFootprintsComponent number={number} />, // Carbon Footprints
];


const getModalWithBackup = (flags: userFlags, customer: Partial<UserInformation>): JSX.Element[] => {
  let backupIndex = 0;
  return [
    flags.membershipInfo
      ? <Box6Component key="box6" number={6} />
      : <Box6Component key="box6" number={6} sum={backupIndex++} variant='kilometers' />, // kilometers_flow

    flags.baggagesInfo
      ? <Box7Component key="box7" number={7} />
      : <Box7Component key="box7" number={7} sum={backupIndex++} />, // aircraft_type

    flags.vivaFanInfo
      ? <Box8Component key="box8" number={8} />
      : <Box8Component key="box8" number={8} sum={backupIndex++} />, // Time flow

    flags.cobrandCardInfo
      ? <Box9Component key="box9" number={9} />
      : <Box9Component key="box9" number={9} sum={backupIndex++} />, // Bundle name

    flags.dotersTickets
      ? <Box10Component key="box10" number={10} />
      : <Box10Component key="box10" number={10} sum={backupIndex++} />, // Promotional booking

    flags.smileFlightCount
      ? <Box11Component key="box11" number={11} />
      : backupModals[backupIndex++](customer, 11), // Carbon Footprints
  ];
};

const CardBoxSwal: React.FC<BoxSwalProps> = ({ customer, selectedBox, setSelectedBox }) => {
  const [isMobile, setIsMobile] = useState(true);
  const [boxHeights, setBoxHeights] = useState<{
    large: number;
    small: number;
  }>({
    large: 550,
    small: 275,
  });
  const [hoveredBoxIndex, setHoveredBoxIndex] = useState<number | null>(null);


  const [userFlags, setUserFlags] = useState<userFlags>({
    membershipInfo: false,
    baggagesInfo: false,
    vivaFanInfo: false,
    cobrandCardInfo: false,
    dotersTickets: false,
    smileFlightCount: false,
  });

  const [customerProfileState, setCustomerProfileState] = useState<CustomerProfileState>({
    profile: null,
    isLoading: true,
  });

  const boxes: CSSProperties[] = [
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '59.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.small },
    { width: isMobile ? '33%' : '19.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '39.9%', height: boxHeights.large },
    { width: isMobile ? '33%' : '99.9%', height: boxHeights.large },
  ];

  const getBoxClass = (box: CSSProperties): string => {
    const heightClass =
      box.height === boxHeights.large ? 'box-large' : 'box-small';
    const widthPercentage = parseInt(box.width as string, 10);
    const widthClass = `box--${widthPercentage}`;
    return `${heightClass} ${widthClass}`;
  };

  const adjustBoxHeights = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 431) {
      setBoxHeights({ large: 130, small: 130 });
      setIsMobile(true);
      return;
    } else if (windowWidth < 768) {
      setBoxHeights({ large: 220, small: 110 });
      setIsMobile(false);
      return;
    } else if (windowWidth < 992) {
      setBoxHeights({ large: 320, small: 160 });
      setIsMobile(false);
      return;
    } else if (windowWidth < 1200) {
      setBoxHeights({ large: 400, small: 200 });
      setIsMobile(false);
      return;
    } else {
      setBoxHeights({ large: 550, small: 275 });
      setIsMobile(false);
    }
  };

  const getIndexMapping = (): number[] => {
    if (isMobile) {
      // En mobile el grid es lineal
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    } else {
      // En desktop ajusta según el diseño gráfico
      return [0, 1, 3, 2, 4, 5, 6, 7, 8, 9, 10, 11];
    }
  };

  const indexMapping = getIndexMapping();


  const handleBoxClick = (visualIndex: number) => {
    console.log(`handleBoxClick(${visualIndex})`);
    const logicalIndex = indexMapping[visualIndex]; // Traduce el índice gráfico a lógico

    if (selectedBox === logicalIndex) {
      console.log('Already selected, reopening modal.');
      // Forzar cierre y reapertura
      setSelectedBox(null); // Resetea el estado brevemente
      setTimeout(() => setSelectedBox(logicalIndex), 0); // Vuelve a seleccionar el índice
    } else {
      console.log('LOGICAL INDEX');
      setSelectedBox(logicalIndex); // Actualiza al nuevo índice lógico
    }
  };

  const timerRef = useRef<number | null>(null);
  const progressBarRef = useRef<HTMLDivElement | null>(null);

  const [modals, setModals] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const flags = helper.getCustomerFlags(customer);
    setUserFlags(flags);

    const profilesUser = helperProfile.getCustomerProfile(customer);
    setCustomerProfileState({
      profile: profilesUser,
      isLoading: false,
    });

    let modalList: JSX.Element[] = [];

    if (isMobile) {
      // Aquí se inicializan los modales del 1 al 5, el 11 con backup, y el 12 al final
      modalList = [
        <Box1Component key="box1" number={1} />,
        <Box2Component key="box2" number={2} />,
        <Box3Component key="box3" number={3} />,
        <Box4Component key="box4" number={4} />,
        <Box5Component key="box5" number={5} />,
        ...getModalWithBackup(flags, customer).map((modal, index) =>
          React.cloneElement(modal, { key: `cardBox-${index}` })
        ),
        // <Modal12Component key="modal12" customer={customer} profileUser={customerProfileState.profile} />
      ];
    }

    if (!isMobile) {
      // Aquí se inicializan los modales del 1 al 5, el 11 con backup, y el 12 al final
      modalList = [
        <Box1Component key="box1" number={1} />,
        <Box2Component key="box2" number={2} />,
        <Box4Component key="box3" number={4} />,
        <Box3Component key="box4" number={3} />,
        <Box5Component key="box5" number={5} />,
        ...getModalWithBackup(flags, customer).map((modal, index) =>
          React.cloneElement(modal, { key: `cardBox-${index}` })
        ),
        // <Modal12Component key="modal12" customer={customer} profileUser={customerProfileState.profile} />
      ];
    }


    // Agregar Modal12 solo si el perfil está disponible
    if (profilesUser) {
      modalList.push(
        <Box12Component key="box12" number={12} />
      );
    }
    setModals(modalList);

  }, [customer, isMobile]);


  useEffect(() => {
    const adjustOnResize = () => adjustBoxHeights();



    // Agrega el listener de resize
    window.addEventListener('resize', adjustOnResize);

    // Limpia el listener en desmontaje
    return () => window.removeEventListener('resize', adjustOnResize);
  }, [isMobile]);

  useEffect(() => {
    // Llama a adjustBoxHeights en el montaje y cambio a móvil
    adjustBoxHeights();
  }, [isMobile])



  return (
    <Masonry>
      {
        boxes?.map((box, index) => (
          <div
            key={index}
            style={box}
            data-id={index}
            className={`box-col ${getBoxClass(box)} ${hoveredBoxIndex === index ? 'box--viewing' : ''
              }`}
            onClick={() => handleBoxClick(index)} // Usa el índice gráfico
            onMouseEnter={() => setHoveredBoxIndex(index)}
            onMouseLeave={() => {
              setTimeout(() => {
                setHoveredBoxIndex(null);
              }, 500);
            }}
          >
            <div className="box">
              <div className="box__inner">
                {modals[index] || <div>Componente no definido</div>}
              </div>
            </div>
          </div>
        ))
      }
    </Masonry>
  );
};

export default CardBoxSwal;
