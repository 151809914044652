import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderComponent from '../../components/shared/header/header.component';
import { UserInformation } from '../../types/vivaaerobus';
import GridBComponent from '../../components/grid/grid.component';
import { getUserInfo } from '../../api/vivaaerobus';
import LoaderGeneral from '../../components/loader/loaderVivaLogo.component';

const HomeScreen = () => {
  const { vivaId } = useParams();
  const [customer, setCustomer] = useState<Partial<UserInformation>>({});
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    if (!vivaId) {
      setIsProcessing(false);
      return;
    }

    getUserInfo(vivaId)
      .then((response) => {
        if (response && response.data) {
          console.log('response', response.data);
          setCustomer(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [vivaId]);

  useEffect(() => {
    window.dataLayer.push({
      event: 'ev_ux',
      category: 'lp_desempacando2023',
      action: 'first_step',
      label: 'open',
    });
  }, []);

  return (
    <>
      {isProcessing && (
        <div className="loader-inline">
          <LoaderGeneral isProcessing={isProcessing} />
        </div>
      )}
      <HeaderComponent />
      <section className="intro-section">
        <div className="container text-center">
          <p className="text-green500 text-64 font-700">
            {customer.userDetails && customer.userDetails.length > 0
              ? customer.userDetails[0]?.firstName
              : ''}
          </p>
          <h1 className="text-white text-120 intro-section__title">
            Destapa tu <strong className="text-green300">2024</strong>
          </h1>
        </div>
      </section>
      {Object.keys(customer).length && <GridBComponent customer={customer} />}
    </>
  );
};
export default HomeScreen;
