import React, { useState, useEffect, useRef } from 'react';
import BoxSwal from '../BoxSwal/boxSwal.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Modal1Component from '../Modal/modal1.component';
import { UserInformation } from '../../types/vivaaerobus';
import TourComponent from '../Tour/tour.component';
import CardBoxSwal from '../CardBoxSwal/CardBoxSwal.component';
// import ShareComponent from '../ShareComponent/ShareComponent';
// import { dataFakeShare } from '../ShareComponent/dataFake';

interface GridProps {
  customer: Partial<UserInformation>;
}

const GridComponent: React.FC<GridProps> = ({ customer }) => {
  const [selectedBox, setSelectedBox] = useState<number | null>(null); // Controla el índice seleccionado

  console.log("COSTUMER", customer);

  // Estado para controlar la visibilidad del componente por inactividad
  const [showInactivityComponent, setShowInactivityComponent] = useState(false);

  // Timers y referencias
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);
  const cooldownTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Constantes de tiempo
  const INACTIVITY_DELAY = 20000; // 10 seg inactivo para mostrar el componente
  const COOLDOWN_PERIOD = 600000; // 10 minutos en ms

  // Estado para controlar si estamos en periodo de inactividad o en periodo de descanso
  const [isCooldown, setIsCooldown] = useState(false);
  const tourRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    // adjustBoxHeights();
    // window.addEventListener('resize', adjustBoxHeights);

    // Al montar iniciamos la detección de inactividad (primer ciclo)
    startInactivityCheck();

    // Eventos de interacción del usuario
    const events = ["mousemove", "mousedown", "keydown", "touchstart", "scroll", "click"];
    events.forEach(evt => {
      document.addEventListener(evt, handleUserActivity, true);
    });

    return () => {
      // window.removeEventListener('resize', adjustBoxHeights);
      events.forEach(evt => {
        document.removeEventListener(evt, handleUserActivity, true);
      });
    };
  }, []);



  const showInactivityComponentRef = useRef(showInactivityComponent);

  useEffect(() => {
    showInactivityComponentRef.current = showInactivityComponent;
  }, [showInactivityComponent]);

  const handleUserActivity = () => {
    console.log("User activity detected, showInactivityComponent (ref):", showInactivityComponentRef.current);
    if (showInactivityComponentRef.current) {
      hideInactivityComponent();
      startCooldown();
    } else if (!isCooldown) {
      resetInactivityTimer();
    }
  };

  const startInactivityCheck = () => {
    setIsCooldown(false);
    resetInactivityTimer();
  };

  const resetInactivityTimer = () => {
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
    }
    inactivityTimerRef.current = setTimeout(() => {
      // Si pasan 5 segundos sin actividad, mostramos el componente
      showInactivityComponentFn();
    }, INACTIVITY_DELAY);
  };

  const showInactivityComponentFn = () => {
    setShowInactivityComponent(true);
  };

  const hideInactivityComponent = () => {
    console.log("Hiding inactivity component");
    setShowInactivityComponent(false);

    if (tourRef.current) {
      // Verifica si el elemento sigue en el DOM y lo remueve
      const tourElement = tourRef.current.querySelector('.tour-build');
      if (tourElement) {
        tourElement.remove();
      }
    }
  };

  const startCooldown = () => {
    // Entramos en el periodo de descanso de 10 minutos donde no chequeamos inactividad
    setIsCooldown(true);

    // Limpiar cualquier timer previo
    if (inactivityTimerRef.current) clearTimeout(inactivityTimerRef.current);

    // Iniciar cooldown de 10 minutos
    cooldownTimerRef.current = setTimeout(() => {
      // Pasados los 10 minutos, volvemos a vigilar inactividad
      startInactivityCheck();
    }, COOLDOWN_PERIOD);
  };

  useEffect(() => {
    console.log("showInactivityComponent changed:", showInactivityComponent);
  }, [showInactivityComponent]);


  return (
    <section className="masonry">
      {showInactivityComponent && (
        <div ref={tourRef} key={`tour-${showInactivityComponent}`} className='tour-container'>
          <TourComponent />
        </div>
      )}
      <div className="container">
        <CardBoxSwal customer={customer} selectedBox={selectedBox} setSelectedBox={setSelectedBox} />
      </div>
      <div>
      </div>
      {selectedBox !== null && (
        <BoxSwal key={selectedBox} index={selectedBox} customer={customer} />
      )}
    </section>
  );
};

export default GridComponent;
