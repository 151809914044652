import React from 'react';

type Props = {
  number?: number;
  variant?: string;
};

const Box11Component: React.FC<Props> = ({ number, variant }) => {
  return (
    <div className="item-box item-box--11">
      <div className={`item-box__number ${variant === 'promotion' ? 'center' : ''}`}>{number}</div>
      {variant !== 'promotion' && (
        <>
          <figure className="item-faceid">
            <img src="/assets/images/grid/faceid.svg" alt="FaceId" />
          </figure>
          <figure className="item-smile-logo">
            <img src="/assets/images/grid/smile-and-fly.svg" alt="Smile & Fly" />
          </figure>
        </>
      )}

      {variant === 'promotion' && (
        <figure className='item-image'>
          <img src="/assets/images/grid/promo.svg" alt="Pomoción" />
        </figure>
      )}
    </div>
  );
};

export default Box11Component;
