import axios from 'axios';
import qs from 'qs';
import api from './index';
import {
  IBackupUser,
  UserInformation,
  UserInformationResponse,
} from '../types/vivaaerobus';
import { newTravelRecord } from './sheetBest';
import { parseUserResponse } from '../helpers/mapUserInfo.helper';

const backupDataClient = axios.create({
  baseURL: 'https://xe4piwkmg2.execute-api.us-east-1.amazonaws.com/prod',
  headers: {
    'x-api-key': 'HiCIif4m6l3aAT6Mwi6FZEZGa7CLdd97xa8KEm9d',
  },
});

const setTravelRecord = async (vivaId: string, user: UserInformation) => {
  try {
    const { userDetails } = user;
    if (userDetails) {
      const travelRecord = {
        vivaId,
        // name: `${firstName} ${lastName}`,
        // email: email,
        visitDate: new Date().toISOString(),
        userAgent: window.navigator.userAgent || '',
      };
      await newTravelRecord(travelRecord);
    }
  } catch (error) {
    console.error('setTravelRecord Error', error);
  }
};

export const getUserInfo = async (vivaId: string) => {
  let response;
  try {
    response = await api.get<UserInformationResponse>(
      `/users/information?${qs.stringify({ viva_id: vivaId })}`
    );
  } catch (error) {
    console.error(error);
  }

  let backupUser;
  try {
    backupUser = await backupDataClient.get<IBackupUser>(`/users/${vivaId}`);
  } catch (error) {
    console.error(error);
  }

  if (response?.data.data) {
    await setTravelRecord(vivaId, response?.data.data);
  }

  if (response) {
    const cloneBackupUser = parseUserResponse(
      response.data,
      backupUser?.data || {}
    );
    return {
      ...response?.data,
      data: {
        ...response?.data.data,
        backupUser: cloneBackupUser,
      },
    };
  }
};
