import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationA from '../../lotties/04-VIVA_EOY24-Asiento_Lugar-A_DESKTOP.json';
import animationB from '../../lotties/04-VIVA_EOY24-Asiento_Lugar-B_DESKTOP.json';
import animationC from '../../lotties/04-VIVA_EOY24-Asiento_Lugar-C_DESKTOP.json';
import animationD from '../../lotties/04-VIVA_EOY24-Asiento_Lugar-D_DESKTOP.json';
import animationAMobile from '../../lotties/04-VIVA_EOY24-Asiento_Lugar-A_TAB-P.json';
import animationBMobile from '../../lotties/04-VIVA_EOY24-Asiento_Lugar-B_TAB-P.json';
import animationCMobile from '../../lotties/04-VIVA_EOY24-Asiento_Lugar-C_TAB-P.json';
import animationDMobile from '../../lotties/04-VIVA_EOY24-Asiento_Lugar-D_TAB-P.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: number;
  customer: Partial<UserInformation>;
}

const Modal4Component: React.FC<Props> = ({ customer }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    number: 1,
    veces: 0,
    animationData: {
      desktop: animationA,
      mobile: animationAMobile,
    },
  });

  const getSeatsInfo = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const { seats } = backupUser;
      if (seats) {
        const { type, type_num } = seats;
        let number = 1;
        let animationData: { desktop: any; mobile: any } = {
          desktop: animationA,
          mobile: animationAMobile,
        };
        switch (type) {
          case 'Window': {
            number = 1;
            animationData = {
              desktop: animationA,
              mobile: animationAMobile,
            };
            break;
          }
          case 'Aisle': {
            number = 2;
            animationData = {
              desktop: animationB,
              mobile: animationBMobile,
            };
            break;
          }
          case 'Middle': {
            number = 3;
            animationData = {
              desktop: animationC,
              mobile: animationCMobile,
            };
            break;
          }
          case '': {
            number = 4;
            animationData = {
              desktop: animationD,
              mobile: animationDMobile,
            };
            break;
          }
        }
        setModalInfo({
          number,
          veces: Number(type_num),
          animationData,
        });
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth < 768);
      }
    };
    handleResize(); // Establecer el valor inicial
    window.addEventListener('resize', handleResize);
    // Limpieza al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => getSeatsInfo(), [customer]);

  return (
    <div className="box-swal box--modal4" data-info={modalInfo.number}>
      <div className="row">
        <div className="box-modal-cols col-vertical-center offset-md-6 col-md-6 col-sm-12 col-12">
          <article className="box-modal-article">
            {(modalInfo.number === 1 ||
              modalInfo.number === 2 ||
              modalInfo.number === 3) && (
                <p className="text-green700 h2 font-300">Hiciste</p>
              )}
            {(modalInfo.number === 1 ||
              modalInfo.number === 2 ||
              modalInfo.number === 3) && (
                <h2 className="text-120 text-green500">
                  {modalInfo.veces} vuelos
                </h2>
              )}
            {modalInfo.number === 1 && (
              <p className="text-green700 h2 box-modal-article__priority">
                sentado en ventanilla
              </p>
            )}
            {modalInfo.number === 2 && (
              <p className="text-green700 h2 box-modal-article__priority">
                en el asiento del pasillo.
              </p>
            )}
            {modalInfo.number === 3 && (
              <p className="text-green700 h2 box-modal-article__priority">
                en el asiento de en medio.
              </p>
            )}
            {modalInfo.number === 4 && (
              <p className="text-green700 h2 box-modal-article__priority">
                ¡Te acomodas en todos lados!
              </p>
            )}
            {modalInfo.number === 1 && (
              <p className="text-green700 h3 font-300">
                ¡Disfrutando del paisaje!
              </p>
            )}
            {modalInfo.number === 2 && (
              <p className="text-green700 h3 font-300">Viajando cómodamente.</p>
            )}
            {modalInfo.number === 3 && (
              <p className="text-green700 h3 font-300">
                ¡La buena plática no faltó!
              </p>
            )}
            {modalInfo.number === 4 && (
              <p className="text-green700 h3 font-300">
                ¿Sabías que este año volaste la misma cantidad de veces en
                ventanilla, pasillo y en medio?
              </p>
            )}
          </article>
        </div>
        <div className={`lottie-player lottie-player--${modalInfo.number}`}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: isMobile
                ? modalInfo.animationData.mobile
                : modalInfo.animationData.desktop,
              rendererSettings: {
                preserveAspectRatio: modalInfo.number === 2 ? 'xMaxYMid slice' : 'xMinYMid slice',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal4Component;
