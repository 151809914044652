import React from 'react';

type Props = {
  number?: number;
  variant?: string;
  sum?: number;
};

const Box6Component: React.FC<Props> = ({ number, variant }) => {
  return (
    <div className="item-box item-box--6">
      {(variant !== 'kilometers') && (
        <>
          <img src="/assets/images/grid/dot.svg" alt="Puntos" className="dot2" />

          <div className="item-box__number">{number}</div>
          <img src="/assets/images/grid/dot.svg" alt="Puntos" className="dot" />
        </>
      )}

      {(variant === 'kilometers') && (
        <>
          <div className="item-box__number_variant">
            {number}
          </div>
          <figure>
            <img src="/assets/images/grid/routes.svg" alt="Rutas" />
          </figure>
        </>
      )}
    </div>
  );
};

export default Box6Component;
