import React from 'react';

type Props = {
  number?: number;
  sum?: number;
};

const Box8Component: React.FC<Props> = ({ number }) => {
  return (
    <div className="item-box item-box--8">
      <div className="item-box__number">{number}</div>
      <figure>
        <img src="/assets/images/grid/pattern-back.svg" alt="Casilla 8" />
      </figure>
    </div>
  );
};

export default Box8Component;
