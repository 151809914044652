import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import animationA from '../../lotties/06-VIVA_EOY24-Doters-A_OpB-DESKTOP.json';
import animationB from '../../lotties/06-VIVA_EOY24-Doters-B_OpB-DESKTOP.json';
import animationC from '../../lotties/06-VIVA_EOY24-Doters-C_OpB-DESKTOP.json';
import animationATab from '../../lotties/06-VIVA_EOY24-Doters-A_OpB-TAB_P.json';
import animationBTab from '../../lotties/06-VIVA_EOY24-Doters-B_OpB-TAB_P.json';
import animationCTab from '../../lotties/06-VIVA_EOY24-Doters-C_OpB-TAB_P.json';
import animationAMobile from '../../lotties/06-VIVA_EOY24-Doters-A_OpB-MOBILE.json';
import animationBMobile from '../../lotties/06-VIVA_EOY24-Doters-B_OpB-MOBILE.json';
import animationCMobile from '../../lotties/06-VIVA_EOY24-Doters-C_OpB-MOBILE.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: number;
  type?: string;
  customer: Partial<UserInformation>;
}

const Modal6Component: React.FC<Props> = ({ customer }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSmallMobile, setIsSmallMobile] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    number: 1,
    veces: 0,
    used: 0,
    animationData: {
      mobile: animationA,
      tablet: animationATab,
      desktop: animationA,
    },
  });

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        const width = window.innerWidth;
        setIsMobile(width < 768); // Pantallas tipo "tablet"
        setIsSmallMobile(width <= 441); // Pantallas móviles pequeñas
      }
    };

    handleResize(); // Establecer el valor inicial

    window.addEventListener('resize', handleResize);

    // Limpieza al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getMembershipInfo = () => {
    const { backupUser } = customer;
    if (backupUser && Object.keys(backupUser).length) {
      const {
        membership_doters_class: dotersClass,
        benefits_used_doters_points_acumulation: dotersPoints,
        benefits_used_doters_points_used: userDotersPoints,
      } = backupUser;
      let number = 1;
      let animationData: any = {
        mobile: animationA,
        tablet: animationATab,
        desktop: animationA,
      };
      switch (dotersClass) {
        case 'Green': {
          number = 1;
          animationData = {
            desktop: animationA,
            tablet: animationATab,
            mobile: animationAMobile,
          };
          break;
        }
        case 'Silver': {
          number = 2;
          animationData = {
            desktop: animationB,
            tablet: animationBTab,
            mobile: animationBMobile,
          };
          break;
        }
        case 'Gold': {
          number = 3;
          animationData = {
            desktop: animationC,
            tablet: animationCTab,
            mobile: animationCMobile,
          };
          break;
        }
      }

      setModalInfo({
        number,
        animationData,
        veces: Number(dotersPoints),
        used: Number(userDotersPoints),
      });
    }
  };

  useEffect(() => getMembershipInfo(), [customer]);

  return (
    <div className="box-swal box--modal6" data-info={modalInfo.number}>
      <div className="row">
        <aside className="box-modal-cols col--lottie col-md-5  col-sm-12 col-12">
          <figure className="doters-logo">
            <img src="../../../assets/images/logo/doters.svg" alt="Doters" />
          </figure>
          <div className="lottie-player">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: isSmallMobile
                  ? modalInfo.animationData.mobile
                  : isMobile
                  ? modalInfo.animationData.tablet
                  : modalInfo.animationData.desktop,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid MEE',
                },
              }}
            />
          </div>
        </aside>
        <div className="box-modal-cols col-vertical-center col-md-7 col-sm-12 col-12">
          <article className="box-modal-article">
            <figure className="doters-logo">
              <img src="../../../assets/images/logo/doters.svg" alt="Doters" />
            </figure>
            <div>
              {modalInfo.number === 1 && (
                <p className="text-white h2 font-300 init-title">
                  Con tus vuelos, ¡ganaste
                </p>
              )}
              {modalInfo.number === 2 && (
                <p className="text-white h2 font-300 init-title">Usaste</p>
              )}
              {modalInfo.number === 3 && (
                <p className="text-white h2 font-300 init-title">
                  Con tus vuelos, ganaste
                </p>
              )}
              {modalInfo.number === 1 && (
                <h2 className="text-green300 h2 number-dooter-info">
                  {`${modalInfo.veces.toLocaleString('en-US')}`} Puntos Doters!
                </h2>
              )}
              {(modalInfo.number === 2 || modalInfo.number === 3) && (
                <h2 className="text-green300 h2 number-dooter-info">
                  {`${modalInfo.veces.toLocaleString('en-US')}`} Puntos Doters
                </h2>
              )}
              {modalInfo.number === 2 && (
                <p className="text-white h2 font-300">para pagar tus vuelos.</p>
              )}
            </div>
            {modalInfo.number === 1 && (
              <p className="text-white h3 font-300">
                Volando y ganando con nuestro programa de lealtad.
              </p>
            )}
            {modalInfo.number === 2 && (
              <p className="text-white h3 font-300 col-lg-10 col-12">
                ¡Y volaste mucho más barato con nuestro programa de lealtad!
              </p>
            )}
            {modalInfo.number === 3 && (
              <p className="text-white h3 font-300 col-lg-10 col-12">
                ¡Y usaste {`${modalInfo.used.toLocaleString('en-US')}`} Puntos Doters para volar aún más
                con nuestro programa de lealtad!
              </p>
            )}
          </article>
        </div>
      </div>
    </div>
  );
};

export default Modal6Component;
