import React, {useState, useEffect} from 'react';
import Lottie from 'react-lottie';
import animation from '../../lotties/Backup06-VIVA_EOY24-Promociones-DESKTOP.json';
import animationMobile from '../../lotties/Backup06-VIVA_EOY24-Promociones-MOBILE.json';
import { UserInformation } from '../../types/vivaaerobus';

interface Props {
  number?: number;
  customer:Partial<UserInformation>;
}

const ModalPBComponent: React.FC<Props> = ({customer, number = 2}) => {
  console.log(customer)
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth < 768);
      }
    };

    handleResize(); // Establecer el valor inicial

    window.addEventListener('resize', handleResize);

    // Limpieza al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const animationMap: { [key: number]: { desktop: any; mobile: any } } = {
    1: {
      desktop: animation,
      mobile: animationMobile,
    },
    2: {
      desktop: animation,
      mobile: animationMobile,
    }
  };

  // Seleccionar la animación adecuada
  const animationData =
    animationMap[number]
      ? isMobile
        ? animationMap[number].mobile
        : animationMap[number].desktop
      : isMobile
      ? animationMobile
      : animation; // Valor predeterminado

  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const promotionalTickets = customer.backupUser?.promotional_bookings


  return (
    <div className="box-swal box--modalPB" data-number={number}>
      <div className="row">
        <aside className="box-modal-cols col-12">
          <article className="fade-in">
            {promotionalTickets !== '' && promotionalTickets !== '0' && (
              <>
                <p className="text-64 text-white">Aprovechaste</p>
                <h2 className="text-64 text-green300">{promotionalTickets}{' '}
                {promotionalTickets === '1' ? 'promoción' : 'promociones'}</h2>
                <p className="text-white h3 font-300">¡y volaste mucho más barato!</p>
              </>
            )}
            {promotionalTickets === '' || promotionalTickets === '0' && (
              <>
                <p className="h3 text-green300 modalPB__title">Este año no usaste ninguna promoción.</p>
                <p className="text-white h3 font-300">¡No te quedes sin ahorrar en 2025 y aprovecha todas las promociones que tendremos para ti!</p>
              </>
            )}
            
          </article>
          <div className="lottie-player">
            <Lottie options={animationOptions} />
          </div>
        </aside>
      
      </div>
      
     
    </div>
  );
};

export default ModalPBComponent