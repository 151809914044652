import axios from 'axios';

const instance = axios.create({
  baseURL:
    'https://api.sheetbest.com/sheets/f512d8de-1898-49e5-9349-f427e19a09e6',
  headers: {
    'x-api-key':
      '9!0Dj3#K2aFENTH9q0Mpce!@HCtbVHnpdtoV1vlXohPdw3oEpn2$lMw4SSs%VXCV',
  },
});

export interface TravelRecord {
  vivaId: string;
  visitDate: string;
  userAgent: string;
}

export const newTravelRecord = async (data: TravelRecord) => {
  try {
    const response = await instance.post<TravelRecord[]>('', data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
