import axios from 'axios';

export default axios.create({
  // baseURL: 'https://api-gw-test.vivaaerobus.io/vb/v1/',
  baseURL: 'https://api-gw.vivaaerobus.com/vb/v1/',
  headers: {
    // 'x-api-key': 'HiCIif4m6l3aAT6Mwi6FZEZGa7CLdd97xa8KEm9d',
    'x-api-key': 'Vri1IdJBVa5IHQfOFhr2h4ALjYx9u2ev1M1UEwWn',
  },
});
