import React from 'react';
// import Lottie from 'react-lottie';
import { ProfileBase } from '../../types/vivaaerobus';
import images from './images';

type Props = {
  data: ProfileBase;
  setOnLoad: React.Dispatch<React.SetStateAction<boolean>>;
};
const ShareComponent = React.forwardRef<HTMLDivElement, Props>(({ data, setOnLoad }, ref) => {
  const { titleShare, flightTitle, flightValue, secondTitleShare, secondTitleValueShare, footerTitle, favoriteDestination, shareType } = data;
  const ShareImage = (shareType !== undefined) ? images[shareType] : null;

  setOnLoad(true);
  return (
    <div className="share-component" ref={ref} style={{ fontFamily: 'Poppins, sans-serif' }}>
      <div className="lottie-container-share">
        {/* <Lottie options={animationOne} /> */}
        {/* <img
          src={profileImage}
          onLoad={() => setOnLoad(true)}
          alt="perfil de viva"
          style={
            {
              background: `url("${profileImage}")`,
            }}
          crossOrigin="anonymous"
        /> */}

        {ShareImage && <ShareImage />}
      </div>
      <div className="body-container">
        <div className="header-share">
          <p className="text-white h3 info-title text-center">{titleShare}</p>
        </div>
        <div className="text-container-primary">
          <div className="content-text w-50">
            <p className="text-primary-content">{flightTitle}</p>
            <p className="text-secondary-content">{flightValue}</p>
          </div>
          <div className="content-text w-50">
            <p className="text-primary-content">{secondTitleShare}</p>
            <p className="text-secondary-content">{secondTitleValueShare}</p>
          </div>
        </div>
        <div className="content-text">
          <p className="text-primary-content">{footerTitle}</p>
          <p className="text-secondary-content">{favoriteDestination}</p>
        </div>
      </div>
      <figure className="footer-share-component">
        <svg width="95" height="38" viewBox="0 0 95 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5652 33.3249C16.0972 34.8089 14.6536 35.6987 12.8717 35.6987C11.0898 35.6987 9.77035 34.8934 9.26286 33.3249L2 12.0333H7.39357L13.0409 30.1456L18.9448 12.0333H24.2115L16.568 33.3249H16.5652Z" fill="white"/>
          <path d="M27.8555 35.6593V12.0305H32.781V35.6565H27.8555V35.6593Z" fill="white"/>
          <path d="M51.116 33.3249C50.6479 34.8089 49.2044 35.6987 47.4225 35.6987C45.6406 35.6987 44.3211 34.8934 43.8136 33.3249L36.5508 12.0333H41.9443L47.5917 30.1456L53.4956 12.0333H58.7623L51.1188 33.3249H51.116Z" fill="white"/>
          <path d="M82.5043 35.2003H77.8212V32.3111H77.6803C76.1155 34.2513 73.6513 36.0001 69.9071 36.0001C64.7898 36.0001 59.01 31.9253 59.0156 23.8351C59.0156 16.6431 64.1385 11.8165 70.2962 11.8193C74.0855 11.8193 76.3608 13.8074 77.7338 15.6068H77.8297V12.5318H82.5156L82.5071 35.2003H82.5043ZM70.9221 31.6015C74.5394 31.6015 77.8692 28.4786 77.8692 23.9843C77.8692 19.2479 74.7904 16.1193 70.9757 16.1193C66.1347 16.1193 63.834 20.0251 63.834 23.8323C63.834 27.7408 66.0811 31.5987 70.9221 31.6015Z" fill="white"/>
          <path d="M88.9501 14.3537C86.7171 14.3537 84.8986 12.5374 84.8986 10.3071V10.0931H84.6843C83.6017 10.0931 82.5838 9.6707 81.8198 8.90757C81.0557 8.14163 80.6328 7.12788 80.6328 6.04655C80.6328 4.96521 81.0529 3.94865 81.8198 3.18552C82.5838 2.41958 83.6017 2 84.6843 2H88.9501C90.0328 2 91.0506 2.4224 91.8147 3.18552C92.5787 3.94865 93.0017 4.96521 93.0017 6.04655V10.3071C93.0017 12.5374 91.1831 14.3537 88.9501 14.3537ZM84.6843 3.88951C84.1064 3.88951 83.565 4.11479 83.159 4.52029C82.7502 4.92861 82.5275 5.46927 82.5275 6.04373C82.5275 6.61819 82.753 7.16167 83.159 7.56717C83.5678 7.97548 84.1092 8.19795 84.6843 8.19795H86.7904V10.3043C86.7904 11.4926 87.7603 12.4585 88.9501 12.4613C90.1399 12.4613 91.107 11.4954 91.107 10.3043V6.04373C91.107 5.46646 90.8814 4.92579 90.4754 4.51748C90.0666 4.10916 89.5253 3.8867 88.9501 3.8867H84.6843V3.88951Z" fill="#73C900"/>
        </svg>
      </figure>
    </div>
  );
});

ShareComponent.displayName = 'ShareComponent';

export default ShareComponent;